import { gql } from '@/shared/graphql/generated';

export const getRuntimeVersionQuery = gql(/* GraphQL */ `
  query GetRuntimeVersion {
    allEnvironmentAddresses {
      nodes {
        runtimeVersion
      }
    }
  }
`);
