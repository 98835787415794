import { Fragment } from 'react';
import Head from 'next/head';
import { Link } from '@/shared/components';
import NotFound from '@/shared/components/NotFound';
import { ArrowIcon } from '@/shared/icons/small';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

export const Layout = ({
  children,
  resourceNotFound = false,
  breadcrumbs,
  hero,
  title,
}: {
  resourceNotFound?: boolean;
  children: JSX.Element[] | JSX.Element | string;
  breadcrumbs?: { text: string; href?: string }[];
  hero?: JSX.Element;
  title?: string | null;
}): JSX.Element => {
  const fullTitle = [title, 'Chainflip Explorer'].filter(Boolean).join(' | ');

  return (
    <div className="flex min-h-screen flex-col items-center overflow-x-hidden">
      <Head>
        <title>{fullTitle}</title>
        <meta property="og:title" content={fullTitle} key="title" />
      </Head>
      <Navbar />
      {/* Empty div with navbar height (since its now fixed) so don't have add margin everywhere else */}
      <div className="h-[74px]" />
      <div className="flex w-full grow flex-col items-center">
        {hero}
        <div className="container w-full grow space-y-4 px-4 py-8">
          <div className="flex flex-row items-center space-x-1 text-12 text-cf-light-2">
            {breadcrumbs?.map((breadcrumb, index) => (
              <Fragment key={breadcrumb.text}>
                {index > 0 && <ArrowIcon direction="right" />}
                {breadcrumb.href ? (
                  <Link href={breadcrumb.href} color="light">
                    {breadcrumb.text}
                  </Link>
                ) : (
                  <div>{breadcrumb.text}</div>
                )}
              </Fragment>
            ))}
          </div>
          <div className="relative">{resourceNotFound ? <NotFound /> : children}</div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
